
export const Container = (props: any) => {
  return (
    <div className="container mx-auto">
      <div className="lg:w-2/3 mx-auto">
        <div className="px-4 py-4">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export const Row = (props: any) => {
  return (
    <div className="py-4">
      {props.children}
    </div>
  )
}