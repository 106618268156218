import Header from '../Element/header'
import Footer from '../Element/footer'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { Paragraph3 } from '../Layout/paragraph'
import { ScrollTop } from '../../utility/ScrollTop'
export default function Components() {
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `個人情報保護方針`
                    }} />
                </Row>

                <Row>
                    当社は個人情報を大切に保護することを重要な社会的使命と認識し、個人情報保護に関する法規範を遵守し、
                    次に示す当社基本方針の具現化と、常に社会的要請の変化に着目しつつ継続的改善に全社を挙げて取り組むことを宣言いたします。
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `個人情報の取り扱いについて`,
                        text: `当社の運営する事業において、お預かりするお客様の個人情報を保護することの重要性を十分に考慮したうえで、適切な個人情報の取得、利用および提供に努めます。
                                また要配慮個人情報は、本人の同意なくこれを取得することはありません。`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `個人情報の利用について`,
                        text: `結婚相手紹介サービス業、その他、当社が運営する画事業において取得する個人情報の利用にあたっては、特定した利用目的の達成に必要な範囲を超えた個人情報の取り扱い（目的外利用）を行いません。また、目的外利用を行うことがないよう社内規定を作成し、従業者に社内規定を遵守させるよう監督に努めます。`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `個人情報の利用目的について`,
                        text: `結婚相手紹介サービス業、当社の各事業において、以下の利用目的において個人情報を利用します。
                                ・入会契約時において、本人確認及びプロフィール作成のために利用します。
                                ・契約の履行を目的とした、ご成婚に向けた当社のサービス提供、カウンセリング等のために利用します。
                                ・当社が加盟する連盟組織のシステム登録、及びシステムでのお相手紹介のために利用します。
                                ・サービスに関連するイベント・パーティの連絡に利用します。`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `個人情報の共同利用について`,
                        text: `当社では加盟する連盟システムにおいて、連盟システムを運営する事業者、また同じくお相手紹介を目的として連盟システムを利用する他の事業者の間で個人情報を共同利用いたします。`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `共同利用する個人情報の項目`,
                        text: `【お見合い成立前】
                                ※共同利用する個人情報の項目については、ご記入いただくプロフィールシートまたはシステムのプロフィール入力画面をご覧ください。
                                ※プロフィールシート、システムのプロフィール画面に入力する個人情報項目の内、以下の情報についてはそれぞれ下記のタイミングで共同利用されます。
                                【お見合い成立後】
                                前項に苗字を追加した項目
                                【交際成立後】
                                前項に名前、電話番号、メールアドレスを追加した項目`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `共同利用する範囲`,
                        text: `【お見合い成立前】
                                ※弊社が加盟する連盟・連盟システム及び各システムを利用する事業者および各システムを利用して活動中の異性会員
                                【お見合い成立後】
                                前項①の苗字についてはお見合いをする事業者、会員間
                                【交際成立後】
                                前項①の名前、電話番号、メールアドレスについては交際する会員間、会員の事業者間`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `共同利用の目的`,
                        text: `【お見合い成立前】
                                事業者間の利用目的：会員へのご紹介、カウンセリング等の役務提供のため
                                会員間の利用目的　：お見合いを申し込むお相手選び、また申し込まれた際のお相手確認等のため
                                【お見合い成立後】
                                事業者間、会員間とも交際継続のため`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `共同利用する個人情報の管理責任者`,
                        text: `個人情報保護管理責任者：※担当者名または代表者名※`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `共同利用する個人情報の取得方法`,
                        text: `事業者が会員から書面・システムへの直接入力により取得`
                    }} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `個人情報の第三者提供について`,
                        text: `お客様から取得した個人情報は、以下の場合を除き、第三者に提供することはありません。

                                ・お客様からの同意を得た場合

                                ・法令に定めがある場合

                                ・本人の同意を得ることが困難であり、生命・身体・財産を保護するために必要な場合。
                                
                                ・本人の同意を得ることが困難であり、公衆衛生の向上・児童の健全な育成の推進のため特に必要がある場合
                                
                                ・国、地方公共団体の事務を遂行する上で、本人の同意を求めると、その推進に支障がある場合。
                                
                                ・個人情報の安全管理措置について
                                安心して個人情報をご提供いただけるように、個人情報の漏洩、滅失または毀損等の危険に対して、物理的、人的、技術的安全管理体制を整え、合理的な安全対策および予防・是正措置を講じます。
                                
                                ・個人情報の開示・訂正・利用停止について
                                当社は個人情報について、お客様からの開示、訂正、利用停止、消去等の請求がある場合には、訂正かつ速やかな対応を講じます。また当社で利用する個人情報が違法に第三者に提供されている訴えがあり、かつ、それが事実であった場合には遅滞なく第三者への提供を停止します。
                                
                                ・個人情報の法令・規範の遵守について
                                大切な個人情報をお預かりする企業として、個人情報保護の取り扱いに関する法令、個人情報保護法に基づいて各所管官庁が作成したガイドライン・指針をはじめとするその他の規範を遵守します。
                                
                                ・個人情報の取り扱いの継続的改善について
                                個人情報を適切に利用し、またその保護を徹底するため、適切なりようについて継続的な見直しおよび改善を行います。
                                
                                ・個人情報に関するお問い合わせについて
                                当社の個人情報の取扱いに関する苦情および相談等があった場合には、下記の個人情報に関する苦情および相談窓口にて、迅速かつ適切な対応をいたします。`
                    }} />
                </Row>

                <Row>
                    <Heading2 item={{
                        text: null
                    }} />

                    <Row>
                        <p className="text-xl">
                            個人情報に関する苦情および相談の問合せ先
                        </p>
                    </Row>

                    <Row>
                        <p className="">
                            株式会社Terra-Cco<br />
                            TEL：03-6303-7333<br />
                            個人情報保護管理責任者：代表取締役　谷村 紀嗣
                        </p>
                    </Row>

                </Row>

                <Footer />
                
            </Container>

        </>
    )
}