import Header from '../Element/header'
import Footer from '../Element/footer'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { useNavigate } from "react-router-dom"
import { ScrollTop } from '../../utility/ScrollTop'
export default function Components() {
    const navigate = useNavigate()
    return (
        <>

            <ScrollTop />
            
            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `特定商取引法に基づく表記`
                    }} />
                </Row>

                <Row>

                    <div className="container mx-auto">
                        <div className="lg:w-2/3 mx-auto">

                            <table className="table-auto w-full">
                                <tbody className="border">
                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">運営者</td>
                                        <td className="border py-2 px-4 text-center">株式会社Terra-Cco</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">住　所</td>
                                        <td className="border py-2 px-4 text-center">〒140-0011 東京都品川区東大井５丁目2-7  磯辺ビル2-202</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">運営責任者</td>
                                        <td className="border py-2 px-4 text-center">谷村　紀嗣</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">電話番号</td>
                                        <td className="border py-2 px-4 text-center">03-6303-7333</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">支払方法</td>
                                        <td className="border py-2 px-4 text-center">クレジットカード　銀行振込</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">役務の提供時期</td>
                                        <td className="border py-2 px-4 text-center">入会手続き及び登録完了より退会のお申し出があった月の末日まで</td>
                                    </tr>

                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">商品以外の必要料金</td>
                                        <td className="border py-2 px-4 text-center">銀行振込の場合、振込み手数料をご負担いただきます。お見合い時の交通費・喫茶代は実費となります。</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 px-4 text-center bg-mariage">中途解約・返金・キャンセルについて</td>
                                        <td className="border py-2 px-4 text-center">入会契約日から８日間以内であれば、無条件で解約することができます。（クーリングオフ）クーリングオフ後でも中途解約可能ですが、初期費用の返金はできません。</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                </Row>

                <Row>
                    <div className="grid gap-2 lg:grid-cols-4">
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/privacy`)}>個人情報取扱い</button></div>
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/copyright`)}>著作権・免責事項</button></div>
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/tokushoho`)}>特定商取引法</button></div>
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/welfare`)}>企業会員制度</button></div>
                    </div>
                </Row>

                <Footer />

            </Container>
        </>
    )
}