export const Heading1 = ({ item }: { item: any }) => {
  return (
    <div className="py-4" key={item.text}>
      <h1 className="text-2xl text-white bg-mariage px-3 py-3">
        {item.text}
      </h1>
    </div>
  )
}

export const Heading2 = ({ item }: { item: any }) => {
  return (
    <div className="divide-y divide-dashed divide-mariage">
      <h2 className="text-xl">
        {item.text}
      </h2>
      <div>

      </div>
    </div>
  )
}
