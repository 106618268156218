import React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './components/template/Home/index'
import Beginners from './components/template/Beginners/index'
import Price from './components/template/Price/index'
import System from './components/template/System/index'
import Faq from './components/template/Faq/index'
import Counselor from './components/template/Counselor/index'
import Data from './components/template/Data/index'
import Privacy from './components/template/Privacy/index'
import Copyright from './components/template/Copyright/index'
import Tokushoho from './components/template/Tokushoho/index'
import Welfare from './components/template/Welfare/index'
import Contact from './components/template/Contact/index'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={`/beginners`} element={<Beginners />} />
        <Route path={`/price`} element={<Price />} />
        <Route path={`/system`} element={<System />} />
        <Route path={`/faq`} element={<Faq />} />
        <Route path={`/counselor`} element={<Counselor />} />
        <Route path={`/data`} element={<Data />} />
        <Route path={`/privacy`} element={<Privacy />} />
        <Route path={`/copyright`} element={<Copyright />} />
        <Route path={`/tokushoho`} element={<Tokushoho />} />
        <Route path={`/welfare`} element={<Welfare />} />
        <Route path={`/contact`} element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
