import Arr2str from '../utility/Arr2str'
import { useNavigate } from "react-router-dom";
const style = [
  'w-4',
  'h-4',
  'text-blue-600',
  'bg-gray-100',
  'border-gray-300',
  'focus:ring-blue-500',
  'dark:focus:ring-blue-600',
  'dark:ring-offset-gray-800',
  'focus:ring-2',
  'dark:bg-gray-700',
  'dark:border-gray-600'
]

export const Checkbox = ({ register }: { register: any }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex">
        <div className="flex-col">
          <div className="flex mr-4 mt-2">
            <input {...register} type="checkbox" value="1" className={Arr2str(style)} />
            <label className="ml-2 text-sm font-medium">
              同意する
              <span>
                <a onClick={() => navigate(`/privacy`)} className="ml-3 text-blue-500 underline">個人情報の取扱いについて</a>
              </span>
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export const CheckboxMultiple = ({ register, items }: { register: any, items: any }) => {
  return (
    <>
      <div className="flex mt-3">
        <div className="flex-col">

          {items.map((item: any, index: any) => (
            <div key={index} className="flex mr-4 mb-2">
              <input {...register} type="checkbox" value={index + 1} className={Arr2str(style)} />
              <label className="ml-2 text-sm font-medium">
                {item}
              </label>
            </div>
          ))}

        </div>
      </div>
    </>
  )
}

export const Textfield = ({ register, placeholder, disabled=false, onChange }: { register: any, placeholder: any, disabled: boolean, onChange: any }) => {
  const style = [
    'appearance-none',
    'block',
    'w-full',
    'text-gray-700',
    'border',
    'border-gray-200',
    'rounded-lg',
    'py-3',
    'px-3',
    'leading-tight',
    'focus:outline-none',
    'focus:bg-white'
  ]

  return (
    <>
      {(disabled == false) ? (
        <input {...register} type="text" placeholder={placeholder} onChange={onChange} className={Arr2str(style)} />
      ) : (
        <input {...register} type="text" placeholder={placeholder} onChange={onChange} className={Arr2str(style)} disabled/>
      )}
    </>
  )
}

export const ZipcodeForm = ({ register, value, placeholder, onChange }: { register: any, value: any, placeholder: any, onChange: any }) => {
  const style = [
    'appearance-none',
    'block',
    'w-full',
    'text-gray-700',
    'border',
    'border-gray-200',
    'rounded-lg',
    'py-3',
    'px-4',
    'leading-tight',
    'focus:outline-none',
    'focus:bg-white'
  ]
  return (
    <>
      {(onChange == null ? (
        <input {...register} type="text" value={value} placeholder={placeholder} className={Arr2str(style)} />
      ) : (
        <input {...register} type="text" value={value} placeholder={placeholder} className={Arr2str(style)} onChange={onChange} />
      ))}
    </>
  )
}

export const Textarea = ({ register }: { register: any }) => {
  const style = [
    'appearance-none',
    'block',
    'w-full',
    'text-gray-700',
    'border',
    'border-gray-200',
    'rounded-lg',
    'py-3',
    'px-3',
    'leading-tight',
    'focus:outline-none',
    'focus:bg-white'
  ]
  return (
    <>
      <textarea {...register} className={Arr2str(style)} rows={5} />
    </>
  )
}
