import { useNavigate } from "react-router-dom";
export default function Component({ items }: { items: any }) {
  const navigate = useNavigate();
  return (
    <>
      {items.map((item: any) => {
        return <img
          key={item.url}
          src={item.src}
          onClick={() => navigate(item.url)}
        />
      })}
    </>
  )
}
