import Header from '../Element/header'
import Footer from '../Element/footer'
import Banner from '../Layout/banner'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { Paragraph2 } from '../Layout/paragraph'
import { ScrollTop } from '../../utility/ScrollTop'
import Img1 from '../../../img/beginners/couple.jpeg'
export default function Components() {
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `初めての方へ`
                    }} />
                    <Banner items={[
                        {
                            src: Img1,
                            url: null,
                        },
                    ]} />
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `Mariage gift の婚活`
                    }} />
                </Row>

                <Row>
                    私は長年、教育業界の営業職に就いておりました。<br />
                    たくさんの方々とお会いしてお話をするうちに「こんなに素敵な方が何故なかなかご結婚されないのだろう？」と<br />
                    思う場面が多く、何かお手伝いができないだろうか…という想いからMariage giftが誕生いたしました。<br />
                    <br />

                    ご成婚していただくには、ただ出会える環境があるだけではダメなんですね。<br />
                    人と人のご縁をつなげるために、人が介入し、そのお一人おひとりに合った婚活のサポートが必要です。<br />
                    <br />
                    Mariage giftは、その想いを大切に。<br />
                    人は一人では生きていけません。とくに大きな災害があった時や今回のようなコロナ禍では、<br />
                    ・「誰かと一緒に生きたい」<br />
                    ・「自分を想ってくれる人にそばにいてほしい」<br />
                    という感情が湧かれた方も多くいらっしゃたのではないでしょうか。<br />
                    <br />
                    自分を想ってくれる誰かがいるということは本当に幸せなことです。<br />
                    結婚のその先の幸せ。<br />
                    家族が増える喜び…。<br />
                    結婚はゴールではなくスタートです。<br />
                    <br />
                    そのスタートに立つため、効率よく楽しくご縁のあるパートナーに出会っていただきたいと思います。<br />
                    みなさまには、幸せなご成婚を心から願っております。<br />
                    Mariage giftがそのお手伝いをさせていただきます。<br />
                    <br />

                    みなさま、お一人おひとり性格もお相手に求める希望条件も違いますので、当相談所では入会時のファースト<br />
                    カウンセリングでしっかりとヒアリングを行っております。<br />
                    <br />
                    どんな想いでお越しいただけたのか、婚活に対するイメージなどをうかがい、それぞれの個性に合ったサポートで、<br />
                    時に寄り添い、時に背中を押して、楽しく婚活が進められるよう心掛けております。<br />
                    <br />
                    希望するお相手から、あなたを選んでいただけるように、多方向からの戦術（「愛される力」「選ばれる力」）を<br />
                    アドバイスしております。最初はご不安なことも多々おありかと思いますが、その不安を払拭していただく為に、<br />
                    入会時のファーストカウンセリングを非常に大切にしております。<br />
                    <br />
                    少しでもご興味のある方は是非一度お問い合わせください。<br />
                    <br />
                    勇気のいることかもしれませんが、変わりたい！のでしたら、是非その勇気をもって一歩踏み出してください。<br />
                    IBJ の会員は個人情報書類が揃わないと入会手続きができない連盟（日本結婚相談所連盟）です。<br />
                </Row>

                <Row>

                </Row>

                <Row>
                    <Heading2 item={{
                        text: `～ Mariage gift が選ばれる理由 ～`
                    }} />
                </Row>

                <Row>
                    【特徴１】会員数・成婚数 No.1<br />
                    <br />
                    IBJ の会員は個人情報書類が揃わないと入会手続きができない連盟（日本結婚相談所連盟）です。<br />
                    <br />
                    75,000名を超える確かな会員の中から、お相手となる方とのステキな出会いをお手伝いします。<br />
                    <br />
                    【特徴２】徹底した成婚主義<br />
                    <br />
                    Mariage gift だからできる、婚活から目標６ヶ月後のご成婚に向けて、会員さまをしっかりフルサポートします。<br />
                    <br />
                    【特徴３】少人数限定会員制<br />
                    <br />
                    会員さまお一人お一人としっかり向き合ってご成婚へ。<br />
                    本気で結婚を考えているからこそ、プロのアドバイスやサポートが大切になってきます。<br />
                    だからMariage gift は、少人数限定会員制で会員様と一緒に、ご成婚に向けて活動を手厚くサポートいたします。<br />
                </Row>

                <Row>

                </Row>

                <Row>
                    <Heading2 item={{
                        text: `解決できる理由`
                    }} />
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 Point１】`
                    }} />
                    <Paragraph2 item={{
                        text: `システムを利用して多くの方に出会うことができます。普段の生活では出会うことがないようなお相手とも結婚を前提に向き合うことができます。多くの方と出会うことで確実に世界は広がります。

                                今までの生活で結婚相手にめぐり会えていない人が早く結婚相手に出会うには「出会いの数を増やすこと」「世界を広げること」が必要です。`
                    }} />
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 Point２】`
                    }} />
                    <Paragraph2 item={{
                        text: `全員が結婚願望のある独身者です。結婚を望んでいるのかわからないお付き合いを続けることは時間の無駄。

                                限りある時間の中で理想の結婚相手と出会いたいのであれば「結婚を望んでいる相手と出会うこと」が重要です。`
                    }} />
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 Point３】`
                    }} />
                    <Paragraph2 item={{
                        text: `お相手の身元がはっきりしている安全な出会いです。IBJ連盟では、お相手と会うために定められた書類を提出していただき、各所で身元の確認を行っています。外見や趣味、お酒たばこの嗜好はもちろん、本来聞きづらい年収や家族構成、親との同居を希望するかなども事前に知ることができます。

                                書類の用意が面倒と思うかもしれませんが、その分入会者は厳正に審査されており、「真剣にお相手を探している証」でもあるのです。`
                    }} />
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 Point４】`
                    }} />
                    <Paragraph2 item={{
                        text: `交際にはルールがあり一定の期間で判断します。お互いの時間を大切にするため無駄に交際を長引かせることをしません。
                                
                                仲人が間に入り、ご本人同士の気持ちを優先しながら結婚を真剣に考える環境を整えますので「お互いの気持ちが合えば結婚までの展開はスムーズ」です。`
                    }} />
                </Row>

                <Footer />

            </Container>

        </>
    )
}