import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { RiCloseFill } from 'react-icons/ri'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoMdInformationCircle } from 'react-icons/io'
import { IconContext } from 'react-icons'

import Logo from '../../../img/common/logo.jpeg'
import { Link } from "react-router-dom"
import { ImHome3 } from 'react-icons/im'
import { FaArrowAltCircleRight } from 'react-icons/fa'

export default function Component() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="mx-auto">

      <div className="lg:w-12/12 mx-auto">
        <div className="w-full bg-mariage">
          <div className="lg:w-2/3 mx-auto">
            <div className="grid hidden lg:grid lg:grid-cols-2 md:grid-cols-2">
              <div className="text-left text-white text-bold text-xs py-1">
                半年以内の成婚にこだわる結婚相談所 Mariage gift（マリアージュ ギフト）
              </div>
              <div className="text-right text-white text-bold text-xs py-1">
                日本結婚相談所連盟・IBJ正規加盟店（全国出張可能）
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:w-2/3 mx-auto">
        <div className="sticky top-0 z-50 px-4 py-4 w-full bg-white">
          <div className="flex items-center justify-between">

            <img src={Logo} width="20%" alt="アイコン" className="cursor" onClick={() => navigate(`/`)} />

            <ul className="flex items-center space-x-8 lg:flex">
              <li>
                <Link className="inline-flex items-center rounded px-3 py-3 bg-mariage border" to={`/contact`}>
                  <IoMdInformationCircle className="mr-2" /> 無料相談 お問い合わせ
                </Link>
              </li>
            </ul>

            <div className="lg:hidden">

              <button className="p-2" onClick={() => setIsMenuOpen(true)} >
                <div className="flex flex-col w-10 h-10 justify-center items-center">
                  <IconContext.Provider value={{ color: 'gray', size: '100%' }}>
                    <AiOutlineMenu />
                    <div className="text-gray-500 text-[10px]">
                      メニュー
                    </div>
                  </IconContext.Provider>
                </div>
              </button>

              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full">
                  <div className="p-5 bg-white shadow-sm">
                    <div className="flex items-center justify-between mb-4">
                      <button className="p-2 -mt-2 -mr-2" onClick={() => setIsMenuOpen(false)} >
                        <RiCloseFill />
                      </button>
                    </div>
                    <nav>
                      <ul className="space-y-4">
                        <li >
                          <div className="w-full">
                            <Link className="inline-flex items-center " to={`/`}>
                              <ImHome3 className="mr-2" /> ホーム
                            </Link>
                          </div>
                        </li>
                        <li >
                          <div className="w-full">
                            <Link className="inline-flex items-center " to={`/beginners`}>
                              <FaArrowAltCircleRight className="mr-2" /> 初めての方
                            </Link>
                          </div>
                        </li>
                        <li >
                          <div className=" w-full">
                            <Link className="inline-flex items-center " to={`/price`}>
                              <FaArrowAltCircleRight className="mr-2" /> 入会費用
                            </Link>
                          </div>
                        </li>
                        <li >
                          <div className=" w-full">
                            <Link className="inline-flex items-center " to={`/system`}>
                              <FaArrowAltCircleRight className="mr-2" /> ご成婚流れ
                            </Link>
                          </div>
                        </li>
                        <li >
                          <div className="w-full">
                            <Link className="inline-flex items-center" to={`/faq`}>
                              <FaArrowAltCircleRight className="mr-2" /> サポートQ&A
                            </Link>
                          </div>
                        </li>
                        <li >
                          <div className="w-full">
                            <Link className="inline-flex items-center " to={`/counselor`}>
                              <FaArrowAltCircleRight className="mr-2" /> カウンセラー
                            </Link>
                          </div>
                        </li>
                        <li >
                          <div className=" w-full">
                            <Link className="inline-flex items-center " to={`/data`}>
                              <FaArrowAltCircleRight className="mr-2" /> 会員データ
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}

            </div>

          </div>
        </div>
      </div>

      <div className="lg:w-12/12 mx-auto">
        <div className="w-full bg-mariage shadow-lg">
          <div className="lg:w-2/3 mx-auto">
            <div className="flex justify-center items-center text-center hidden lg:flex bg-mariage">
              <div className="w-full">
                <Link className="inline-flex items-center rounded bg-mariage " to={`/`}>
                  <ImHome3 className="mr-2" /> ホーム
                </Link>
              </div>
              <div className="py-4 w-full">
                <Link className="inline-flex items-center rounded bg-mariage " to={`/beginners`}>
                  <FaArrowAltCircleRight className="mr-2" /> 初めての方
                </Link>
              </div>
              <div className="py-4 w-full">
                <Link className="inline-flex items-center rounded bg-mariage " to={`/price`}>
                  <FaArrowAltCircleRight className="mr-2" /> 入会費用
                </Link>
              </div>
              <div className="py-4 w-full">
                <Link className="inline-flex items-center rounded bg-mariage " to={`/system`}>
                  <FaArrowAltCircleRight className="mr-2" /> ご成婚流れ
                </Link>
              </div>
              <div className="py-4 w-full">
                <Link className="inline-flex items-center rounded bg-mariage " to={`/faq`}>
                  <FaArrowAltCircleRight className="mr-2" /> サポートQ&A
                </Link>
              </div>
              <div className="py-4 w-full">
                <Link className="inline-flex items-center rounded bg-mariage " to={`/counselor`}>
                  <FaArrowAltCircleRight className="mr-2" /> カウンセラー
                </Link>
              </div>
              <div className="py-4 w-full">
                <Link className="inline-flex items-center rounded bg-mariage " to={`/data`}>
                  <FaArrowAltCircleRight className="mr-2" /> 会員データ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
