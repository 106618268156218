import Header from '../Element/header'
import Footer from '../Element/footer'
import Banner from '../Layout/banner'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { Paragraph4 } from '../Layout/paragraph'
import { ScrollTop } from '../../utility/ScrollTop'
import Img1 from '../../../img/faq/img1.jpeg'
export default function Components() {
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `サポートＱ＆Ａ`
                    }} />
                    <Banner items={[
                        {
                            src: Img1,
                            url: null,
                        },
                    ]} />
                </Row>

                <Row>
                    <Paragraph4 item={{
                        head: `婚活はしたいけど、時間がなかなか取れません`,
                        text: `Mariage giftでは、仕事のお忙しい方でも、充実した婚活ができるようにサポートいたします。
                                    活動中はLINEや電話・メールなどでも随時相談を受け付けております。
                                    また、会員様と直接打ち合わせが必要なときは、駅近のカフェなど会員様の動きやすいところでの面談も可能です。`,
                    }} />
                </Row>

                <Row>
                    <Paragraph4 item={{
                        head: `理想とする相手をあまりイメージできないのですが...`,
                        text: `ほとんどの会員様は自分の理想とするお相手のイメージは、ぼんやりしたところから始まります。
                                    Mariage gift では、会員様とのコミュニケーションを通じて、ご自身も気づかなかった具体的なご希望を引き出しながら理想のお相手をかがします。`,
                    }} />
                </Row>

                <Row>
                    <Paragraph4 item={{
                        head: `お見合いはどのようにするのですか？`,
                        text: `初回のお見合いは、待ち合わせ時間より少し早めに来ていただくようお願いしております。遅刻対策とおしゃべり脳の活性化。一番ステキな状態でお見合いに行ってらっしゃいませ。ご希望があればお相手に会員様をご紹介させていただくためのお時間を同席させていただきます。お見合いで楽しいお時間を過ごしていただけますよう、事前サポートもしっかりいたします。`,
                    }} />
                </Row>

                <Row>
                    <Paragraph4 item={{
                        head: `あまりお付き合いの経験がないから、デートは不安です`,
                        text: `Mariage gift では、交際時期に合わせて、お店選びやデートプランなども一緒に考えながらご提案いたします。
                                    ご成婚へつなげるための大切な期間です。特に男性会員様へはMariage giftが提案するファッションサービスでお見合いやデートのコーディネートをサポートいたします。`,
                    }} />
                </Row>

                <Row>
                    <Paragraph4 item={{
                        head: `プロポーズは？`,
                        text: `お二人にとって素敵な日でありますように。
                                    Mariage giftでは、最高のプロポーズを迎えられますよう、お相手の仲人さんと連携を取りながらサポートいたします。`,
                    }} />
                </Row>

                <Row>
                    <Paragraph4 item={{
                        head: `結婚までサポートしてくれるんですか？`,
                        text: `お見合い、交際からいよいよプロポーズ、そしてご成婚に向けてのご準備です。結婚指輪のご購入、結婚式場選びやご予約、ハネムーンのご予約などなど…、決めることは盛り沢山です。ご安心ください。Mariage giftでは、プロのウェディングコンシェルジュが、両家の顔合わせ会場から結婚式場をはじめとするご成婚に向けた様々な手配をサポートいたします。`,
                    }} />
                </Row>

                <Footer />

            </Container>
            
        </>
    )
}