import { useNavigate } from "react-router-dom";
export const Grid4 = ({ items }: { items: any }) => {
  const navigate = useNavigate();
  return (
    <div className="grid gap-2 lg:grid-cols-4 md:grid-cols-2">
      {items.map((item: any, key: number) => (
        <div key={key}>
          <img
              src={item.img}
              onClick={() => navigate(item.url)}
              className="w-full object-cover object-center"
            />
        </div>
      ))}
    </div>
  )
}
