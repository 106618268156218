import Header from '../Element/header'
import Footer from '../Element/footer'
import Banner from '../Layout/banner'
import { Container, Row } from '../Element/frame'
import { Grid4 } from '../Layout/grid'
import { Heading1, Heading2 } from '../Layout/heading'
import { Paragraph1 } from '../Layout/paragraph'
import { ScrollTop } from '../../utility/ScrollTop'
import Img1 from '../../../img/home/ichigo-ichie.jpeg'
import Img2 from '../../../img/home/beginners.jpeg'
import Img3 from '../../../img/home/price.jpeg'
import Img4 from '../../../img/home/system.jpeg'
import Img5 from '../../../img/home/faq.jpeg'
import Img6 from '../../../img/home/mariage_gift.jpeg'
import Img7 from '../../../img/home/dress.jpeg'
export default function Components() {
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>

                <Row>
                    <Banner items={[
                        {
                            src: Img1,
                            url: null,
                        },
                    ]} />
                </Row>

                <Row>
                    <Grid4 items={[
                        {
                            text: "初めての方",
                            url: 'beginners',
                            img: Img2
                        },
                        {
                            text: "コース料金プラン",
                            url: 'price',
                            img: Img3
                        },
                        {
                            text: "ご成婚までの流れ",
                            url: 'system',
                            img: Img4
                        },
                        {
                            text: "Q&A",
                            url: 'faq',
                            img: Img5
                        },
                    ]} />
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `Mariage gift（マリアージュ ギフト）の由来`
                    }} />
                </Row>

                <Row>
                    <Paragraph1 item={{
                        text: `マリアージュ（Mariage）
                            ２つの異なる存在が出会い融合することで新たな素晴らしいものが生まれる
                            'Mariage gift（マリアージュ ギフト）' は、そんな素敵な出会いをつなぐお手伝いをいたします。`
                    }} />
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `婚活をお考えの皆さまへ`
                    }} />
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-1/3">
                                    <img src={Img6} className=" w-full object-cover object-center" />
                                </div>
                                <div className="sm:w-2/3 sm:text-left">
                                    <ul>
                                        <li className="py-3">
                                            Mariage giftは、幅広い年齢層に対応しております。<br />
                                            特にアラフォー、アラフィフには自信があります。<br />
                                            長い人生の中では、困難を伴うこともございましたが、側にいる人の支えで乗り越えてきました。その経験が人生の糧となり、自らの人生観・考え方に影響を与えてきました。
                                        </li>
                                        <li className="py-3">
                                            そして、婚活カウンセラーとして様々なお客様の相談をお受けしながら、ご成婚に導くこと、微力ながらお力添えをさせて頂けていることを幸せに思っております。
                                        </li>
                                        <li className="py-3">
                                            初婚の方も再婚をお考えの方も、是非一度お話を聞きに来てください。<br />
                                            少人数制にこだわり、会員さまからたくさんお話を伺い、お一人おひとりに合った徹底したサポートをご提供いたします。
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-2/3 sm:text-left">
                                    <ul>
                                        <li className="py-3">
                                            <span className="text-xl text-bold">
                                                Mariage gift（マリアージュ ギフト）は、『ご成婚第一主義』
                                            </span>
                                        </li>
                                        <li className="py-3">
                                            恋愛経験がなくても、忙しくても<br />
                                            土日がお仕事の方でも、<br />
                                            しっかりサポートいたします。<br />
                                            出張カウンセリングもいたします。<br />
                                            ぜひ一度、Mariage gift（マリアージュ ギフト）のドアをノックしてみてください。
                                        </li>
                                        <li className="py-3">
                                            「お見合い」や「結婚相談所」…<br />
                                            モテない人が利用するような暗いイメージをお持ちではないですか？<br />
                                            実は、本気な方ほど結婚相談所に登録して活動をしています。<br />
                                            そして、短期成婚を目指します。　幸せを迎えます。
                                        </li>
                                    </ul>
                                </div>
                                <div className="sm:w-1/3">
                                    <img src={Img7} className="w-full object-cover object-center" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Footer />

            </Container>
            
        </>
    )
}