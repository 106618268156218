import { useNavigate } from "react-router-dom";
import { Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'

import { Link } from "react-router-dom"
import { FaArrowAltCircleRight } from 'react-icons/fa'

import Img1 from '../../../img/common/reservation.jpeg'
import Img2 from '../../../img/common/partner1.png'
import Img3 from '../../../img/common/partner2.jpeg'
import Img4 from '../../../img/common/partner3.png'
import Img5 from '../../../img/common/partner4.png'
import Img6 from '../../../img/common/banner1.jpeg'
import Img7 from '../../../img/common/banner2.png'
import Img8 from '../../../img/common/banner3.png'
import Img9 from '../../../img/common/banner4.png'

export default function Component() {
  const navigate = useNavigate();
  return (
    <>

      <Row>

      </Row>

      <Row>
        <div className="flex justify-between">
          <div>

          </div>
          <div>
            <Link className="inline-flex items-center bg-mariage text-white shadow-md rounded px-4 py-2" to={`/data`}>
              <FaArrowAltCircleRight className="mr-2" /> 会員データ 詳細
            </Link>
          </div>
          <div>

          </div>
        </div>
      </Row>

      <Row>
        <div className="flex justify-between">
          <div>

          </div>
          <div>
            <a className="w-full" href={'https://mariage-gift.com/contact'}>
              <img src={Img1} className=" w-full object-cover object-center shadow-md" />
            </a>
          </div>
          <div>

          </div>
        </div>
      </Row>

      <Row>
        <div className="grid gap-2 lg:grid-cols-4">
          <div><button className="w-full bg-mariage text-white shadow-md rounded px-4 py-2" onClick={() => navigate(`/privacy`)}>個人情報取扱い</button></div>
          <div><button className="w-full bg-mariage text-white shadow-md rounded px-4 py-2" onClick={() => navigate(`/copyright`)}>著作権・免責事項</button></div>
          <div><button className="w-full bg-mariage text-white shadow-md rounded px-4 py-2" onClick={() => navigate(`/tokushoho`)}>特定商取引法</button></div>
          <div><button className="w-full bg-mariage text-white shadow-md rounded px-4 py-2" onClick={() => navigate(`/welfare`)}>企業会員制度</button></div>
        </div>
      </Row>

      <Row>
        <Heading1 item={{
          text: `Partner`
        }} />
      </Row>

      <Row>
        <div className="grid gap-2 lg:grid-cols-4 place-items-center">
          <div>
            <a className="w-full" href={'http://www.topweddingphotographer.tokyo/'} target="_blank" rel="noopener noreferrer">
              <img src={Img2} className=" w-full object-cover object-center" />
            </a>
          </div>
          <div>
            <a className="w-full" href={'https://magic-design.org/work/'} target="_blank" rel="noopener noreferrer">
              <img src={Img3} className=" w-full object-cover object-center" />
            </a>
          </div>
          <div>
            <a className="w-full" href={'https://www.eastmoon-intl.jp/'} target="_blank" rel="noopener noreferrer">
              <img src={Img4} className=" w-full object-cover object-center" />
            </a>
          </div>
          <div>
            <a className="w-full" href={'https://shines.work/'} target="_blank" rel="noopener noreferrer">
              <img src={Img5} className=" w-full object-cover object-center" />
            </a>
          </div>
        </div>
      </Row>

      <Row>
        <Heading2 item={{
          text: null
        }} />
      </Row>

      <Row>
        <div className="grid gap-2 lg:grid-cols-4 place-items-center">
          <div>
            <img src={Img6} className=" w-full object-cover object-center" />
          </div>
          <div>
            <img src={Img7} className=" w-full object-cover object-center h-24" />
          </div>
          <div>
            <img src={Img8} className=" w-full object-cover object-center h-24" />
          </div>
          <div>
            <img src={Img9} className=" w-full object-cover object-center h-24" />
          </div>
        </div>
      </Row>

      <Row>
        <p className="text-sm">
          当社は、登録会員数および成婚数No.1の日本結婚相談所連盟（※）の加盟相談所です。<br />
          ※日本マーケティングリサーチ機構2022年1月調べ_IBJ日本結婚相談所連盟登録会員に関する調査<br />
          （成婚数：2021年実績、会員数：2021年12月末時点、大手結婚相談所・連盟を対象）
        </p>
      </Row>




    </>
  )
}
