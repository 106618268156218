import Header from '../Element/header'
import Footer from '../Element/footer'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { useNavigate } from "react-router-dom"
import { ScrollTop } from '../../utility/ScrollTop'
import Img1 from '../../../img/counselor/img1.jpeg'
export default function Components() {
    const navigate = useNavigate()
    return (
        <>
            <ScrollTop />
            <Header />
            <Container>

                <Row>
                    <Heading1 item={{
                        text: `婚活カウンセラー`
                    }} />
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-2/3 sm:text-left">
                                    <ul>
                                        <li className="py-4 pr-4 text-xl">
                                            Mariage gift の「婚活カウンセラー」富樫 恵智子 です。
                                        </li>
                                        <li className="py-4 pr-4">
                                            ホームページをご覧くださいましてありがとうございます。<br />
                                            Mariage giftは仲人型の結婚相談所です。<br />
                                            真剣に結婚したいと思っている方は<br />
                                            <span className="text-red">会員数が多い相談所</span>で常に専任カウンセラーが
                                            <span className="text-red">アドバイスをしてくれる環境に身を置く</span><br />
                                            ことをお考えいただき、相談所を選んでいただきたいと思います。<br />
                                            Mariage giftは、IBJの正規加盟店、会員数は業界トップです。<br />
                                            婚活が初めてという方にも、長引く婚活にもう疲れてしまったという方にも、<br />
                                            お一人おひとりの心に寄り添い、徹底したサポートでご成婚に導きます。
                                        </li>
                                        <li className="py-4 pr-4 text-xl">
                                            人と人をつなぐ Mariage gift（マリアージュ・ギフト）は<br />
                                            素敵な出会いをつなぐお手伝いをいたします。
                                        </li>
                                        <li className="py-4 pr-4">
                                            婚活で大切なことは、<span className="text-red">常に話を聞いてもらい、
                                                アドバイスをしてくれる人が側にいる</span>ことだと思います。
                                            お一人おひとりに寄り添ったサポートをするために、少人数制とさせて頂いておりますので、是非、
                                            リラックスしてあなたのご性格、ご趣味、結婚観やお相手に対するご希望条件などをお聞かせください。
                                            貴方の心に寄り添いながら、短期間で幸せなパートナーを探し、ご成婚へ導きます。
                                        </li>
                                    </ul>
                                </div>
                                <div className="sm:w-1/3">
                                    <img src={Img1} className=" w-full object-cover object-center" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `結婚相談所情報`
                    }} />
                </Row>

                <Row>
                    <div className="container mx-auto">
                        <div className="lg:w-2/3 mx-auto">

                            <table className="table-auto w-full">
                                <tbody className="border">
                                    <tr className="">
                                        <td className="border py-2 text-center bg-mariage">相談所名</td>
                                        <td className="border py-2 text-center">Mariage gift（マリアージュ・ギフト）</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">婚活カウンセラー</td>
                                        <td className="border py-2 text-center">富樫 恵智子</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">所在地</td>
                                        <td className="border py-2 text-center">〒140-0011 東京都品川区東大井５丁目2-7  2-202</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">最寄り駅</td>
                                        <td className="border py-2 text-center">大井町駅 （JR京浜東北線・りんかい線・大井町線）</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">営業時間</td>
                                        <td className="border py-2 text-center">11:00 ～ 19:00 <br /> 会員さまは、営業時間外でもサポートいたします</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">定休日</td>
                                        <td className="border py-2 text-center">月曜日</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">業務内容</td>
                                        <td className="border py-2 text-center">結婚相談業 （婚活パーティー、イベント、セミナー等の企画・運営）<br />福利厚生コンサルティング 、サービス業全般コンサルティング</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">運営会社</td>
                                        <td className="border py-2 text-center">株式会社 Terra-Cco</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `代表カウンセラーの想い`
                    }} />
                </Row>

                <Row>
                    現在、独身者の9割は「結婚したい」「結婚するつもりでいる」と言われています。<br />
                    <br />
                    結婚する気があるのに結婚出来ない理由…<br />
                    ・「仕事が忙しくて出会いがない」<br />
                    ・「周りにタイプの人がいない」<br />
                    ・「気になる人はいても、アクションが起こせない」<br />
                    ・「出会いの場には積極的に参加しているのに、交際に発展しない」<br />
                    ・「交際がなかなか続かない」　　みなさまそのように仰います。<br />
                    <br />
                    「結婚したい」とお考えの方にとって最も大切なことは<br />
                    あなたのお考えと同じく<span className="text-red">「結婚したい」と思っているお相手がいる環境に身を置くこと</span>。<br />
                    身元のしっかりしている会員さまが揃っていて、かつ適切なアドバイスやサポートを受けられる<br />
                    環境の整った結婚相談所で、一日も早くご活動をスタートさせることだと思います。<br />
                    <br />
                    新しいことを始める時には勇気が必要です。<br />
                    <br />
                    その勇気をもって、一歩踏み出してみてください。<br />
                    人は一人では生きていけません。大きな災害時や今回のようなコロナ禍では、「誰かと一緒に生きていきたい」と<br />
                    皆感じるようです。自分を心配してくれたり、想ってくれる誰かがいるということは、幸せなことです。<br />
                    そして家族が増える喜び。結婚の先の幸せは無限です。皆さまには、お幸せなご成婚を心から願っております。<br />
                    <br />
                    Mariage giftがそのお手伝いができたら最高に幸せです。<br />
                </Row>

                <Row>
                    <div className="grid gap-2 lg:grid-cols-4">
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/privacy`)}>個人情報取扱い</button></div>
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/copyright`)}>著作権・免責事項</button></div>
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/tokushoho`)}>特定商取引法</button></div>
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/welfare`)}>企業会員制度</button></div>
                    </div>
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `オフィス所在地`
                    }} />
                </Row>

                <Row>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6487.609629237711!2d139.736163!3d35.607881!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188a7dec5652dd%3A0xca2545f202e14813!2z44OG44Op44OD44Kz!5e0!3m2!1sja!2sus!4v1677495125452!5m2!1sja!2sus"
                        className="w-full"
                        height="480"
                        loading="lazy"
                    ></iframe>
                </Row>

                <Footer />

            </Container>

        </>
    )
}