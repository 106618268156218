import Header from '../Element/header'
import Footer from '../Element/footer'
import { Container, Row } from '../Element/frame'
import { Heading1 } from '../Layout/heading'
import { ScrollTop } from '../../utility/ScrollTop'
export default function Components() {
    return (
        <>
            <ScrollTop />
            
            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `著作権について`
                    }} />
                </Row>

                <Row>
                    弊社のホームページ上の文書（商品画像情報等含む）に関する著作権は、特別の記載がない限り、すべて弊社ならびにサイト制作会社に帰属します。本ホームページをご利用いただく際には、非営利目的およびお客様内部の使用に限り、これらの文書を複製することができます。
                </Row>

                <Row>
                    文書に弊社の著作権が表示がされている場合は、当該著作権を表示を付したまま複製していいただくことが必要です。営利目的による複製、あるいは翻訳、有線送信等、上記以外の著作権法上の利用はできませんので、ご注意ください。
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `免責事項について`
                    }} />
                </Row>

                <Row>
                    弊社は、弊社が運営/管理するウェブサイト（以下、「本サイト」といいます）の運営にあたり、下記の各条項に定める事項については、免責されるものとします。本サイトをご利用のお客様（以下、単に「お客様」といいます）は、本免責事項の内容をご承諾頂いたものと見なしますので、ご了承ください。
                </Row>

                <Row>
                    第一条<br />
                    本サイトに掲載する情報には充分に注意を払っていますが、その内容について保証するものではありません。弊社は本サイトの使用ならびに閲覧によって生じたいかなる損害にも責任を負いかねます。また、本サイトを装ったウェブサイトによって生じた損害にも責任を負いかねます。本サイトのURLや情報は予告なく変更される場合があります。
                </Row>

                <Row>
                    第二条<br />
                    弊社は、本サイトにおける各種サービスまたは各種情報の提供またはその遅滞、変更、中断、中止、停止もしくは廃止、その他本サイトに関連して発生したお客様または第三者の損害について、一切の責任を負わないものとします。情報の閲覧やサービスの提供を受けるにあたっては、法令上の義務に従った上、お客様ご自身の責任において行っていただきますようお願いいたします。
                </Row>

                <Row>
                    第三条<br />
                    弊社は、本サイトからリンクしている他のウェブサイトに含まれている情報、サービス等については、一切関知しておらず、一切の責任を負わないものとします。リンク先のウェブサイトは、そのウェブサイトが掲げる条件に従い、お客様ご自身の責任においてご利用下さい。
                </Row>

                <Row>
                    弊社は、お客様の便宜のためにこれらのウェブサイトへのリンクを提供しているにすぎず、これらのウェブサイトの利用や掲載商品、サービス等を推奨するものではありません。また、これらのリンクは、弊社とリンク先のウェブサイトを管理・運営する法人・個人との間に、必ずしも提携・協力等の特別な関係があることを意味するものではありません。
                </Row>

                <Footer />

            </Container>

        </>
    )
}