import Header from '../Element/header'
import Footer from '../Element/footer'
import Banner from '../Layout/banner'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { Paragraph3 } from '../Layout/paragraph'

import { MdStars } from 'react-icons/md'
import { IconContext } from 'react-icons'

import Img1 from '../../../img/price/img1.jpeg'
import Img2 from '../../../img/price/img2.jpeg'
import Img3 from '../../../img/price/img3.jpeg'

import { ScrollTop } from '../../utility/ScrollTop'

export default function Components() {
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `入会費用`
                    }} />
                    <Banner items={[
                        {
                            src: Img1,
                            url: null,
                        },
                    ]} />
                </Row>

                <Row>
                    <Paragraph3 item={{
                        head: `入会金`,
                        text: `入会時に必要となる各種事務手続き費用です。`
                    }} />
                    <Paragraph3 item={{
                        head: `登録料`,
                        text: `入会時のプロフィール作成・お見合いシステム登録・スタジオ予約、その他登録掲載に要する費用です。`
                    }} />
                    <Paragraph3 item={{
                        head: `活動サポート費`,
                        text: `ご成婚に向けた会員活動へのサポート全般 （情報管理を含む）に要する費用です。`
                    }} />
                    <Paragraph3 item={{
                        head: `月会費`,
                        text: `会員として活動するためのシステム利用料や登録情報の管理などに要する費用です。入会月からかかります。`
                    }} />
                    <Paragraph3 item={{
                        head: `お見合い料`,
                        text: `お見合いを申し込む側・受ける側、どちらにもかかります。
                                お見合いが成立しますと、お日にち調整・前日の確認連絡・当日の対応・翌日の結果報告などの業務にかかる費用です。`
                    }} />
                    <Paragraph3 item={{
                        head: `成婚料`,
                        text: `ご成婚に至った時にお支払いいただく料金です。
                                成婚とは、結婚、婚約またはそれらと同等の結果（「結婚の口約束」「宿泊を伴う旅行」「婚前交渉」「同棲」「交際期間が6か月を経過した場合」など）は成婚とみなします。
                                また「成婚」における相手とは、当社会員に限らず、当社提供のIBJシステム内で出会った他会員等を含むものとします。`
                    }} />
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `料金（税込表記）`
                    }} />
                </Row>

                <Row>

                    <div className="container mx-auto">
                        <div className="lg:w-2/3 mx-auto">

                            <table className="table-auto w-full">
                                <tbody className="border">
                                    <tr className="">
                                        <td className="border py-2 text-center bg-mariage">入会金（初回）</td>
                                        <td className="border py-2 text-right">52,000円　</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">登録料（初回）</td>
                                        <td className="border py-2 text-right">10,200円　</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">活動サポート費（初回）</td>
                                        <td className="border py-2 text-right">100,000円　</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">月会費</td>
                                        <td className="border py-2 text-right">7,500円　</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">お見合い料</td>
                                        <td className="border py-2 text-right">5,500円　</td>
                                    </tr>
                                    <tr>
                                        <td className="border py-2 text-center bg-mariage">ご成婚料</td>
                                        <td className="border py-2 text-right">275,000円　</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                </Row>

                <Row>
                    <Heading2 item={{
                        text: `入会資格`
                    }} />
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-1/3">
                                    <img src={Img2} className=" w-full object-cover object-center" />
                                </div>
                                <div className="sm:w-2/3 sm:text-left">
                                    <ul>
                                        {[
                                            { text: '真剣にご結婚を考えていらっしゃる方' },
                                            { text: '前向きに活動していただける方' },
                                            { text: '男女とも20歳以上の方' },
                                            { text: '男性は定職・定収入があること' },
                                        ].map((item: any) => (
                                            <li key={item.text} className="py-3">
                                                <div className="inline-flex ml-4">
                                                    <IconContext.Provider value={{ color: 'gray', size: '24px' }}>
                                                        <MdStars />
                                                    </IconContext.Provider>
                                                    {item.text}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `ご提出いただく書類`
                    }} />
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-2/3 sm:text-left">
                                    <ul>
                                        <li className="py-4 pr-4">
                                            弊社は、個人情報書類が揃わないと入会手続きができない連盟（日本結婚相談所連盟）規則となっております。
                                            嘘偽りの無い本気の結婚を目指す方たちの集まりですので、下記書類の提出をお客様にお願いしております。
                                        </li>
                                        <li className="py-4 pr-4">
                                            必要書類の提出により、身元の確実な方のみの入会を徹底しております。
                                        </li>
                                        {[
                                            { text: '住民票（3か月以内の原本：マイナンバー記載無し）' },
                                            { text: '独身証明書（3か月以内の原本：市区町村役所で取得）' },
                                            { text: '写真付き身分証明書（運転免許証など）' },
                                            { text: '最終学歴証明書（原本：卒業証書・卒業証明書いずれか）' },
                                            { text: '収入証明書（直近の源泉徴収票・確定申告書・所得証明のいずれか）' },
                                            { text: '勤務先の確認ができるもの（社員証や健康保険証）' },
                                            { text: '医師・弁護士、その他国家資格やそれに準ずる資格をお持ちの方はその証明書のコピー' },
                                            { text: '３ヶ月以内に撮影されたお写真（２枚）' },
                                        ].map((item: any) => (
                                            <li key={item.text} className="py-1">
                                                <div className="inline-flex ml-4">
                                                    <IconContext.Provider value={{ color: 'gray', size: '24px' }}>
                                                        <MdStars />
                                                    </IconContext.Provider>
                                                    {item.text}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="sm:w-1/3">
                                    <img src={Img3} className=" w-full object-cover object-center" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `クーリングオフについて`
                    }} />
                </Row>

                <Row>
                    <div className="inline-flex ml-8">
                        <IconContext.Provider value={{ color: 'red', size: '24px' }}>
                            <MdStars />
                        </IconContext.Provider>
                        入会契約から8日以内であれば、無条件に入会契約を解除することができます。
                        この場合、契約時にご提出いただいた書類やお支払いいただいた費用は全額をお返しします。
                    </div>
                </Row>

                <Footer />

            </Container>

        </>
    )
}