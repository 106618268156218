import Header from '../Element/header'
import Footer from '../Element/footer'
import Banner from '../Layout/banner'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { Paragraph2 } from '../Layout/paragraph'
import { ScrollTop } from '../../utility/ScrollTop'
import Img1 from '../../../img/system/img1.jpeg'
import Img2 from '../../../img/system/img2.png'
import Img3 from '../../../img/system/img3.jpeg'
import Img4 from '../../../img/system/img4.jpeg'
export default function Components() {
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>
                
                <Row>
                    <Heading1 item={{
                        text: `ご成婚までの流れ`
                    }} />
                    <Banner items={[
                        {
                            src: Img1,
                            url: null,
                        },
                    ]} />
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `「ご入会からご成婚まで」流れ`
                    }} />
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 STEP ０】無料カウンセリング`
                    }} />
                    <Paragraph2 item={{
                        text: `お客様と弊社でお互いに日程調整し、平日のお仕事終わりや土日の昼など様々な時間・場所に対応いたします。
                                入会から婚活、そしてご成婚までの流れを詳しく説明させていただきます。
                                お客様が納得して婚活をスタートさせるための大切な時間です。おおよそ９０分程度です。
                                
                                まずは無料カウンセリングで、あなたの性格や趣味、お仕事、結婚観、希望する異性の条件やタイプをお聞かせください。
                                ご成婚までイメージできるよう内容についてご説明いたします。`
                    }} />
                </Row>

                <Row>
                    <div className="flex justify-center">
                        <div>
                            <img
                                key={1}
                                src={Img3}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 STEP １】ご入会手続き・ファーストカウンセリング`
                    }} />
                    <Paragraph2 item={{
                        text: `「入会書類」（入会費用ページをご確認ください）の準備が整いましたら、「契約書」ならびに「重要事項説明書」のご説明をさせていただきます。十分にご納得いただけますようご説明いたします。
                                
                                その後、署名をいただきます。そして、お客様と弊社でそれぞれ保管いたします。
                                いよいよ婚活開始です。
                                
                                ファーストカウンセリングでは、具体的にお客様の結婚観・趣味やお考えなど、じっくり時間をかけてうかがいます。
                                ヒアリング内容をもとに、クラウド上に全国公開される「お見合いプロフィール」を一緒に作成いたします。`
                    }} />
                </Row>

                <Row>
                    <div className="flex justify-center">
                        <div>
                            <img
                                key={2}
                                src={Img3}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 STEP ２】お相手検索・お見合い`
                    }} />
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-1/4">
                                    <img src={Img2} className=" w-full object-cover object-center" />
                                </div>
                                <div className="sm:w-3/4 sm:text-left">
                                    <ul>
                                        {[
                                            { text: 'さあ！スタートです。一緒に活動する中で多くの時間を要するのが「お見合い相手探し」です。' },
                                            { text: '日本結婚相談所連盟（IBJ）のデータベースへお客様と共に入り検索します。　お気に入り会員様のデータを整理し、お見合いのお申込み申請をいたします。' },
                                            { text: 'ここでたくさんの方とお見合いをしていただけますよう、Mariage giftはいつも会員様とご一緒に伴走いたします。安心してたくさんのご縁を見つけてください。' },
                                            { text: '交際期間に入りますと、交際に集中していただきたいと考えております。なので、このお見合い期間で多くの方とお会いしていただきたいと思います。 ' },
                                        ].map((item: any) => (
                                            <li key={item.text} className="py-3">
                                                <div className="inline-flex ml-8">
                                                    {item.text}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="flex justify-center">
                        <div>
                            <img
                                key={3}
                                src={Img3}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 STEP３】お見合い`
                    }} />
                    <Paragraph2 item={{
                        text: `お見合いのお日にちや場所は双方の相談所にお任せください。一流のホテルラウンジやお洒落なカフェでセッティングいたします。

                                また、お見合いや初デートでは、何を着たらいいんだろう・何を話したらいいんだろうなど、緊張と不安はたくさんありますね。

                                お相手の方に「また会いたい」と思っていただけるためのアドバイスをしっかりサポートいたします。`
                    }} />
                </Row>

                <Row>
                    <div className="flex justify-center">
                        <div>
                            <img
                                key={4}
                                src={Img4}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 STEP４】交際スタート`
                    }} />
                    <Paragraph2 item={{
                        text: `お見合いで、お互いに「次回も会いたい」「お話したい」となりましたら、管理画面で互いのカウンセラーに「交際希望」を伝え、
                                管理画面が「交際管理画面」に遷移します。
                                
                                お相手のフルネーム・携帯番号の交換が行われ、カウンセラー同士が調整した「ファーストコール日時」に男性から
                                ファーストコールしていただきます。交際に集中していただきたいので、お見合いの時期に一気にお見合いをしていただき、
                                ２名～３名の方と同時期に交際できるようにスケジューリングしていきます。
                                
                                Mariage giftは、会員様と一緒にしっかり伴走いたします。
                                交際デート４回～５回あたりで「真剣交際」を意識した発言や、告白をして頂くことが多いです。`
                    }} />
                </Row>

                <Row>
                    <div className="flex justify-center">
                        <div>
                            <img
                                key={5}
                                src={Img4}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 STEP５】真剣交際へ`
                    }} />
                    <Paragraph2 item={{
                        text: `「真剣交際」では、サイト上でも男女それぞれの画像は ♡ マークに覆われ、サイト上でお見合いの受付できない状態に入ります。

                                ご成婚にむけて、お互いにお相手をお一人にしぼり、ご成婚へ向けての最終確認デートを重ねていただきます。
                                
                                「真剣交際」に入りますと、恋愛感情も生まれお互いのゴールが見えてきます。２回～３回のデートでお互いに気持ちが固まり、
                                カウンセラーに「ご成婚（退会）」の意思が告げられます。
                                
                                男性会員様はこの真剣交際の一ヶ月の間に、婚約・プロポーズ時のご準備もいたします。`
                    }} />
                </Row>

                <Row>
                    <div className="flex justify-center">
                        <div>
                            <img
                                key={6}
                                src={Img4}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading1 item={{
                        text: `【 STEP６】ご成婚手続き（退会）`
                    }} />
                    <Paragraph2 item={{
                        text: `おめでとうございます！
                                めでたくお二人は結婚相談所ご卒業でございます。
                                正式な退会手続きをされた後、お二人で記念旅行などはいかがでしょうか？
                                
                                これから「ご結婚」に向けての準備がはじまります。
                                
                                日本結婚相談所連盟（IBJ）のジュエリー購入特典、お引越し特典、ファイナンシャルプランナー特典、マンション購入特典など
                                ご結婚に向けて必要なサポートを受けることができます。`
                    }} />
                </Row>

                <Row>
                    <div className="flex justify-center">
                        <div>
                            <img
                                key={7}
                                src={Img4}
                            />
                        </div>
                    </div>
                </Row>


                <Row>
                    <Heading1 item={{
                        text: `【 Celebration】ご成婚お祝い食事会`
                    }} />
                    <Paragraph2 item={{
                        text: `おめでとうございます！
                                ささやかですが、男女のご成婚カップル様とご一緒にランチ会をさせていただいております。
                                お二人のステキな未来を祝して乾杯！`
                    }} />
                </Row>

                <Footer />

            </Container>

        </>
    )
}