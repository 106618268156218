import Header from '../Element/header'
import Footer from '../Element/footer'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { useNavigate } from "react-router-dom"
import { ScrollTop } from '../../utility/ScrollTop'

import { Textfield } from '../../form/Form'
import { Textarea } from '../../form/Form'
import { Checkbox } from '../../form/Form'

import { init, send } from 'emailjs-com';
import { useForm } from "react-hook-form"
import Img from '../../../img/contact/img.png'

export default function Components() {

    const { register, handleSubmit, setValue } = useForm()

    function sendJS(data: any) {
        if (data.agreement == false || data.agreement.length == 0) {
            alert('個人情報の取扱いについてへ同意してください。')
        } else if (data.name == '') {
            alert('お名前を入力してください。')
        } else if (data.email == '') {
            alert('メールアドレスを入力してください。')
        } else if (data.phone == '') {
            alert('TEL番号を入力してください。')
        } else if (data.questions == undefined || data.questions == '') {
            alert('ご相談・お問い合わせ内容を入力してください。')
        } else {
            // EmailJS
            const publicKey = process.env.REACT_APP_PUBLIC_KEY
            const serviceID = process.env.REACT_APP_SERVICE_ID
            const templateID = process.env.REACT_APP_TEMPLATE_ID
            console.dir([publicKey, serviceID, templateID])
            if (publicKey !== undefined && serviceID !== undefined && templateID !== undefined) {
                console.dir('reached2.')
                init(publicKey);
                send(serviceID, templateID, data).then((result) => {
                    alert('お問い合わせありがとうございました。ご入力いただいたメールアドレスに確認メールをお送りしました。確認メールが届かない場合はお手数ですが、info@terra-cco.jp までご連絡ください。')
                }, (error) => {
                    console.dir(error)
                    alert('お問い合わせの送信に失敗しました。お手数ですが、info@terra-cco.jp までご連絡ください。')
                });
            }
        }
    }

    const contact = async (data: any) => {
        sendJS(data)
    }

    const navigate = useNavigate()
    return (
        <>
            <ScrollTop />
            <Header />
            <Container>

                <Row>
                    <Heading1 item={{
                        text: `無料相談 お問い合わせ`
                    }} />
                </Row>



                <Row>

                    <form onSubmit={handleSubmit(contact)} className="max-w-xl m-auto bg-white bg-opacity-25 rounded border divide-y">


                        <img src={Img} className="w-full object-cover object-center p-2" alt="" />

                        <div className="flex flex-col sm:flex-row p-2">
                            <div className="">


                                <div>
                                    Mariage gift へのお問い合わせは下記のフォームへお願いします。
                                </div>
                                <div>
                                    3営業日以内の返信を心がけています。
                                </div>
                                <div>
                                    返信が確認できなかった場合「迷惑フォルダ」をご確認ください。
                                </div>

                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row">
                            <div className="sm:w-1/3 bg-mariage">
                                <ul>
                                    <li className="m-3 text-md font-bold">
                                        お名前
                                    </li>
                                </ul>
                            </div>
                            <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left py-2 px-4">
                                <Textfield
                                    register={register('name')}
                                    placeholder={null}
                                    disabled={false}
                                    onChange={null}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row">
                            <div className="sm:w-1/3 bg-mariage">
                                <ul>
                                    <li className="m-3 text-md font-bold">
                                        メールアドレス
                                    </li>
                                </ul>
                            </div>
                            <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left py-2 px-4">
                                <Textfield
                                    register={register('email')}
                                    placeholder={null}
                                    disabled={false}
                                    onChange={null}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row">
                            <div className="sm:w-1/3 bg-mariage">
                                <ul>
                                    <li className="m-3 text-md font-bold">
                                        TEL番号
                                    </li>
                                </ul>
                            </div>
                            <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left py-2 px-4">
                                <Textfield
                                    register={register('phone')}
                                    placeholder={null}
                                    disabled={false}
                                    onChange={null}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row">
                            <div className="sm:w-1/3 bg-mariage">
                                <ul>
                                    <li className="m-3 text-md font-bold">
                                        お問い合わせ
                                    </li>
                                </ul>
                            </div>
                            <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left py-2 px-4">
                                <Textarea register={register('questions')} />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row p-2">
                            <div className="text-sm font-bold text-gray-500">
                                送信するには個人情報の取扱いについてへの同意が必要です。
                                <span className="text-red-500 text-sm">必須</span>
                                <Checkbox register={register('agreement')} />
                            </div>
                        </div>

                        <button type="submit" className="w-full bg-mariage font-bold px-4 py-2" >
                            送信する
                        </button>

                    </form>
                </Row>

                <Footer />

            </Container>

        </>
    )
}