import Header from '../Element/header'
import Footer from '../Element/footer'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { ScrollTop } from '../../utility/ScrollTop'
export default function Components() {
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `企業会員制度・福利厚生のご案内`
                    }} />
                </Row>

                <Row>
                    未婚化や晩婚化が進む昨今、企業が福利厚生として婚活サービスをご利用されるケースが増えています。<br />
                    私達は、従業員様が幸せな結婚をしてご家庭を築かれ、よりお仕事が充実することで、将来的に企業の安定にも繋がると考えております。 詳細は下記ボタンより。お気軽にお問合せください。
                </Row>

                <Row>
                    Mariage gift では企業の従業員様とそのご家族様向けのサービスとして、大変お得な「企業会員制度」を設けました。<br />
                    ・企業様の費用負担０円！<br />
                    ・従業員様とそのご家族様は、御社の社員証をご提示頂くだけでご利用いただけます。
                </Row>

                <Row>
                    様々な企業様に「福利厚生」としてご利用いただき、IBJシステムならではの多くの出会いと質の高いパートナー探しの場をご提供いたします。また、Mariage gift では、親切丁寧なサポートで大切な社員様とそのご家族様をご結婚へと導きます。
                </Row>

                <Row>
                    詳細は下記ボタンより。お気軽にお問合せください。
                </Row>

                <Footer />

            </Container>

        </>
    )
}