import { MdStars } from 'react-icons/md'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { IconContext } from 'react-icons'



export const Paragraph1 = ({ item }: { item: any }) => {
  return (
    <div key={item.text}>
      <p className="bg-mariage text-white text-xl border rounded-xl px-6 py-6">
        {item.text.split('\n').map((text: any) => {
          return (<> {text} <br /> </>)
        })}
      </p>
    </div>
  )
}

export const Paragraph2 = ({ item }: { item: any }) => {
  return (
    <div key={item.text}>
      <p className="outline-mariage outline-dashed rounded-xl px-6 py-6">
        {item.text.split('\n').map((text: any) => {
          return (<> {text} <br /> </>)
        })}
      </p>
    </div>
  )
}

export const Paragraph3 = ({ item }: { item: any }) => {
  return (
    <div key={item.text} className="py-2">
      <div className="inline-flex">
        <IconContext.Provider value={{ color: 'gray', size: '24px' }}>
          <MdStars />
        </IconContext.Provider>
        <p className="text-lg">{item.head}</p>
      </div>
      <div className="pl-6">
        {item.text.split('\n').map((text: any) => {
          return (<> {text} <br /> </>)
        })}
      </div>
    </div>
  )
}

export const Paragraph4 = ({ item }: { item: any }) => {
  return (
    <div key={item.head} className="outline-mariage outline-dashed rounded-xl px-6 py-6 divide-y divide-dashed">
      <div className="inline-flex py-2">
        <IconContext.Provider value={{ color: 'red', size: '24px' }}>
          <AiFillQuestionCircle />
        </IconContext.Provider>
        <p className="text-lg pl-1">
          {item.head}
          </p>
      </div>
      <div className="inline-flex py-2">
        <p className="text-lg pl-6">
          {item.text.split('\n').map((text: any) => {
            return (<> {text} <br /> </>)
          })}
        </p>
      </div>
    </div>
  )
}

export const Paragraph5 = ({ item }: { item: any }) => {
  return (
    <div key={item.text}>
      <p className="bg-mariage rounded-xl text-xl px-6 py-6">
        {item.text.split('\n').map((text: any) => {
          return (<> {text} <br /> </>)
        })}
      </p>
    </div>
  )
}

