import Header from '../Element/header'
import Footer from '../Element/footer'
import { Container, Row } from '../Element/frame'
import { Heading1, Heading2 } from '../Layout/heading'
import { Paragraph5 } from '../Layout/paragraph'

import Img1 from '../../../img/data/img1.jpeg'
import Img2 from '../../../img/data/img2.jpeg'
import Img3 from '../../../img/data/img3.jpeg'
import Img4 from '../../../img/data/img4.jpeg'

import { useNavigate } from "react-router-dom"
import { ScrollTop } from '../../utility/ScrollTop'

export default function Components() {
    const navigate = useNavigate()
    return (
        <>

            <ScrollTop />

            <Header />

            <Container>

                <Row>
                    <Heading1 item={{
                        text: `会員データ`
                    }} />
                </Row>

                <Row>
                    <Paragraph5 item={{
                        text: `Mariage gift は、日本全国にある3,101社以上の結婚相談所が加盟する、
                                日本最大級の結婚相談所ネットワーク「日本結婚相談所連盟（IBJ）」の正規加盟店です。
                                
                                安心安全のお見合いを 毎月48,456件 以上ご提供している「日本結婚相談所連盟（IBJ）」について、
                                数字を用いてご紹介します。　(2022年1月現在)`
                    }} />

                </Row>

                <Row>
                    <Heading2 item={{
                        text: `成婚者数の推移`
                    }} />
                </Row>

                <Row>
                    日本結婚相談所連盟（IBJ）に登録する会員のうち、2019年は約12,000名※がご成婚退会されています。
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-2/3">

                                    <Row>
                                        <div className="container mx-auto">
                                            <div className="lg:w-2/3 mx-auto">
                                                <table className="table-auto w-full">
                                                    <tbody className="border">
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">2018年成婚者数</td>
                                                            <td className="border py-2 text-center">9,701 名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">2019年成婚者数</td>
                                                            <td className="border py-2 text-center">11,909 名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">2020年成婚者数</td>
                                                            <td className="border py-2 text-center">12,249 名</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <Row>
                                                    ※日本結婚相談所連盟のシステム外での成婚者を含む
                                                </Row>
                                                
                                            </div>
                                        </div>
                                    </Row>



                                    <Row>
                                        なかでも日本結婚相談所連盟（IBJ）に登録する会員同士の成婚は、<br />
                                        2020年は8,624名うまれており、 前年度比で110%と年々向上しています。
                                    </Row>

                                </div>
                                <div className="sm:w-1/3 sm:text-left">
                                    <img src={Img1} className=" w-full object-cover object-center" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `会員の年齢層`
                    }} />
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-2/3">

                                    <Row>
                                        会員の年齢は、30代前半～40代前半がボリュームゾーン。
                                    </Row>

                                    <Row>
                                        生活が安定しており、信頼してお相手を紹介しあえる方々が多いのが特徴です。
                                    </Row>

                                    <Row>
                                        <div className="container mx-auto">
                                            <div className="lg:w-2/3 mx-auto">
                                                <table className="table-auto w-full">
                                                    <tbody className="border">
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">～399万</td>
                                                            <td className="border py-2 text-center">5,214名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">400～499万</td>
                                                            <td className="border py-2 text-center">6,565名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">500～699万</td>
                                                            <td className="border py-2 text-center">10,912名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">700～999万</td>
                                                            <td className="border py-2 text-center">6,524名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">1000万～</td>
                                                            <td className="border py-2 text-center">3,704名</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <Row>
                                                    ※2021年8月時点
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>

                                </div>
                                <div className="sm:w-1/3 sm:text-left">
                                    <img src={Img2} className="w-full object-cover object-center" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `男性会員の年収`
                    }} />
                </Row>

                <Row>
                    男性の公開年収は、500～699万円がボリュームゾーン。<br />
                    これは、女性が結婚相手に求める年収についての一般的なアンケート結果と同じです。<br />
                    国税庁発表の民間企業給与所得者の平均年収は420万円（平成27年）ですので、結婚相手を探すためのデータベースとしては良質です。
                </Row>

                <Row>
                    <div className="flex flex-wrap">
                        <div className="w-screen">
                            <div className="flex flex-col sm:flex-row">
                                <div className="sm:w-2/3">

                                    <Row>
                                        ＜平均年収＞
                                        <p className="text-center">
                                            IBJ会員 ＞ 国税庁発表の民間企業所得者
                                        </p>
                                    </Row>

                                    <Row>
                                        <div className="container mx-auto">
                                            <div className="lg:w-2/3 mx-auto">
                                                <table className="table-auto w-full">
                                                    <tbody className="border">
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">～29才</td>
                                                            <td className="border py-2 text-center">5,301名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">30才～34才</td>
                                                            <td className="border py-2 text-center">13,774名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">35才～39才</td>
                                                            <td className="border py-2 text-center">16,805名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">40才～44才</td>
                                                            <td className="border py-2 text-center">12,963名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">45才～49才</td>
                                                            <td className="border py-2 text-center">10,161名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">50才～54才</td>
                                                            <td className="border py-2 text-center">5,680名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">55才～59才</td>
                                                            <td className="border py-2 text-center">3,024名</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border py-2 text-center bg-mariage">60才～</td>
                                                            <td className="border py-2 text-center">2,940名</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <Row>
                                                    ※2021年8月時点
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>

                                </div>
                                <div className="sm:w-1/3 sm:text-left">
                                    <img src={Img3} className=" w-full object-cover object-center" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Heading2 item={{
                        text: `会員の学歴`
                    }} />
                </Row>

                <Row>
                    短大・四大・大学院を卒業した方が70％以上を占めるのが特徴です。<br />
                    しっかりとしたご職業に就いている会員が多いため、紹介も安心して行えます。
                </Row>

                <Row>
                    <div className="container mx-auto">
                        <div className="lg:w-2/3 mx-auto">
                            <img src={Img4} className=" w-full object-cover object-center" />
                        </div>
                    </div>

                </Row>

                <Row>
                    <div className="grid gap-2 lg:grid-cols-2">
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/price`)}>プラン＆料金はこちらから</button></div>
                        <div><button className="w-full bg-mariage text-white rounded px-4 py-2" onClick={() => navigate(`/counselor`)}>相談所概要はこちら</button></div>
                    </div>
                </Row>
                
                <Footer />

            </Container>

        </>
    )
}